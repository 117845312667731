import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    light: {
      text1: '#323B4B',
      text4: '#B0B7C3',
      text2: '#4E5D78',
      inputbg: '#F8F9FF',
      cardbg: '#FFFFFF',
      cardbg2: '#FFFFFF',
      tabcolor: '#323B4B',
      tooltipbg: '#FAFBFC',
      tooltipbg1: '#F5F5F5',
      sideBarBg: '#081a51',
      imported: '#0F62FF',
      radioBorder: '#6B5BD2',
      tradebg: '#62737F',
      homeTxt: '#62737F',
      headerBg: '#FFFFFF',
      routerBg: '#EDF2F7',
      textColor: '#4E5D78',
      progressPillBg: '#FFFFFF',
      text3: '#B0B7C3',
      divider: '#F1F1F1',
      sidebarTxt: '#CCD2E3',
      sidebarTopic: '#51CBFF',
      red: '#CC3936',
      orange: '#F6AD55',
      maxColor: '#e35f5f',
      bannerBg: '#0F62FF',
      tryNow: '#0F62FF',
      bannerText: '#201C41',
    },
    dark: {
      text1: '#F8F9FF',
      text4: '#B0B7C3',
      text2: '#F8F9FF',
      inputbg: '#3A4776',
      cardbg: '#313F70',
      cardbg2: '#1E2C5F',
      tabcolor: '#A9A9A9',
      tooltipbg: '#0A194E',
      tooltipbg1: '#0A194E',
      sideBarBg: '#313F70',
      imported: '#FFFFFF',
      radioBorder: '',
      tradebg: '#F8F9FF',
      homeTxt: '#FFFFFF',
      headerBg: 'transparent',
      routerBg: '#0A194E',
      textColor: '#FFFFFF',
      progressPillBg: '#0A194E',
      text3: '#FFFFFF',
      divider: '#F1F1F1',
      sidebarTxt: '#CCD2E3',
      sidebarTopic: '#51CBFF',
      red: '#CC3936',
      orange: '#F6AD55',
      maxColor: '#e35f5f',
      bannerBg: '#0E2579',
      tryNow: '#FFFFFF',
      bannerText: '#FFFFFF',
    },
  },
});

export default theme;
